.overflow-auto {
	overflow: auto;
}

.overflow-x-auto {
	overflow-x: auto;
}

.overflow-y-auto {
	overflow-y: auto;
}

.overflow-overlay {
	overflow: auto; // fallback
	overflow: overlay;
}

.overflow-hidden {
	overflow: hidden;
}

.scrollbar-gutter-stable {
	scrollbar-gutter: stable;
}

.resize-none {
	resize: none;
}

.float-left {
	float: inline-start;
}

.float-right {
	float: inline-end;
}

.clear {
	clear: both;
}

@if $use-clearleft-right==true {
	.clear-left {
		clear: inline-start;
	}

	.clear-right {
		clear: inline-end;
	}
}

@if $use-clearhidden==true {
	.clear-hidden {
		clear: both;
		margin: 0;
		padding: 0;
		visibility: hidden;
	}
}

@if $use-clearfix==true {
	.clear-fix::after {
		content: '.';
		display: block;
		block-size: 0;
		clear: both;
		visibility: hidden;
	}
}
