.grid-auto-fill {
	display: grid;
	inline-size: 100%;
	grid-template-columns: repeat(auto-fill, minmax(var(--min-grid-template-column-size, #{rem(100)}), 1fr));
}

.grid-align-icon {
	display: grid;
	grid-template-columns: auto 1fr;
}

.grid-align-icon-center {
	@extend .grid-align-icon;

	align-items: center;
}

.grid-align-icon > *:not(svg, .keep-left) {
	grid-column-start: 2;
}
