.ratio-auto {
	aspect-ratio: auto;
}

.ratio-square {
	aspect-ratio: 1;
}

.ratio-16\/9 {
	aspect-ratio: 16/9;
}

.ratio-5\/1 {
	aspect-ratio: 5/1;
}
